@import "./src/css/vars";

.text-box {
  width: 400px;
  margin-bottom: 40px;
  padding: 30px;
  background-color: $color-5;
  box-shadow: 0 4px 25px rgba(92, 116, 249, 0.15);

  h3 {
    margin-bottom: 20px;
  }

  p {
    margin: 15px 0 0;
  }

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}
