@import "./src/css/vars";

.up-arrow.active {
  display: block !important;
}

.up-arrow {
  display: none;
  position: fixed;
  bottom: 3%;
  right: 2%;
  z-index: 555;
  font-size: 3rem;
  color: $color-2;

  &:hover {
    color: $color-3;
  }
}
