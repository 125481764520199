@import "./src/css/vars";

#cabinet {
  .container {
    h2 {
      text-align: center;
    }

    .video-frame {
      margin: 50px auto 0;
      width: 80%;
      height: auto;
    }
  }
}
