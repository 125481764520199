@import "./src/css/vars";

#contacts {
  .offer {
    .container {
      .title {
        width: 670px;
      }
    }
  }
}
