@import "./src/css/vars";

.choose-supplier {
  .container {
    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .lt {
        position: relative;
        width: 800px;
        height: auto;

        .picture {
          img {
            width: auto;
          }
        }

        .desc {
          height: 100%;
          width: 100%;
          z-index: 3;
          padding: 50px 50px 0;
          background-color: rgba(0, 0, 0, .6);


          .line {
            margin-top: 35px;
            margin-left: -50px;
            margin-bottom: 50px;
          }

          p {
            margin: 35px 0;
            font-size: 1.2rem;
            color: $color-1;
          }
        }
      }

      .rt {
        position: relative;
        width: 330px;
        margin: auto;

        .icon-trident {
          font-size: 7rem;
          color: $color-3;
        }

        h2 {
          width: 350px;
          line-height: 1.2;
          margin: 30px 0 50px;
        }
      }
    }
  }
}
