//====================================================================
/* 576-768px */
//====================================================================
@import "./src/css/vars";

@media screen and (max-width: 768px) {
  .container {
    background-color: rgba(0, 250, 0, $media-color);
    width: 540px;
    padding: 25px 0;
  }

  h1 {
    font-size: 2.7rem;
  }

  h2 {
    font-size: 2.1rem;
  }

  .left {
    width: 100%;
  }

  .right {
    width: 100%;
  }

  .btn {
    color: $color-3;
  }

  nav ul li a {
    line-height: 2.3;
  }

  #callback {
    background-color: $color-3;
  }

  .offer {
    .container .title {
      width: 470px;
      padding: 30px;
    }
  }

  #homepage {
    .numbers {
      .container {
        padding-top: 55px;
        padding-bottom: 0;

        ul li {
          width: 100%;
          margin-bottom: 50px;

          .box span {
            font-size: 3.5rem;
          }
        }
      }
    }

    .choose-supplier {
      .container .wrap {
        position: relative;

        .lt {
          height: 730px;
        }

        .rt {
          position: initial;
          width: 410px;

          .trident {
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 0;
            left: 0;

            i {
              margin: auto;
            }

            h2 {
              margin-left: 30px;
              width: 260px;
            }
          }

          .btn {
            margin-top: 20px;
          }
        }
      }
    }

    .news {
      .container {
        padding-bottom: 0;

        h2 {
          text-align: left;
        }

        ul li {
          .left {
            .picture {
              height: 350px;
            }
          }

          .right {
            margin-top: 25px;

            h3 {
              margin: 15px 0;
            }
          }

          &:nth-child(2) {
            .left {
              order: 1;
            }

            .right {
              order: 2;
            }
          }
        }
      }
    }
  }

  .comfortable-office {
    .container {
      padding: 25px 0;

      .wrap .right {
        margin-top: 50px;
        width: 100%;

        .video-frame {
          width: 100%;
          height: 310px;
        }
      }
    }
  }

  .why-we {
    .container {
      padding-bottom: 15px;

      .wrap {
        .left {
          width: 100%;

          .picture {
            height: 380px;

            img {
              width: inherit;
              height: auto;
            }
          }
        }

        .right {
          margin-top: 35px;

          .wrapper .text-box {
            width: 255px;
          }
        }
      }
    }
  }

  #footer {
    .callback {
      background-color: $color-3;
    }

    .container .wrap {
      .footer-box {
        width: 100% !important;
        margin-bottom: 20px;

        &:nth-child(1) {
          display: flex;
          justify-content: space-between;

          .box {
            margin-top: 10px;
          }

          .logo {
            width: auto;
            height: 65px;

            img {
              width: auto;
              height: 100%;
            }
          }

          .box {
            width: 50%;

            p {
              margin-top: 0;
            }
          }
        }

        &:nth-child(2) {
          width: 50% !important;
          margin: 15px 0 0;

          ul {
            height: 150px;
          }
        }

        &:nth-child(3) {
          width: 50% !important;
          margin-top: 15px;
          margin-bottom: 0;
          order: 1;

          ul {
            height: 150px;
          }
        }

        &:nth-child(4) {
          order: 2;
          margin-top: 130px;
        }
      }
    }

    .development {
      .copy {
        display: inline-block;
        flex-wrap: wrap;

        b {
          display: none;
        }

        a {
          display: block;
          margin: 2px 0;
        }
      }
    }
  }

  #about {
    .why-we .container {
      padding-top: 75px;
    }

    .director .container .wrap .item {
      width: 100%;

      .line {
        margin: 30px 0;
      }

      .picture {
        margin-top: 10px;
      }
    }

    .partners .container {
      padding-bottom: 0;

      ul li {
        width: 100%;
        margin: 12px auto;
      }
    }
  }

  #client {
    .btn {
      width: 315px;
    }

    .conditions .container {
      ul {
        li {
          .left {
            margin: auto 0;
            text-align: left;

            .number i {
              font-size: 10rem;
            }

            .number::after {
              left: 0;
              font-size: 6rem;
              @include transform(0, -50%);
            }
          }

          .arrow-left {
            transform: rotate(43deg);
            -webkit-transform: rotate(43deg);
            -moz-transform: rotate(43deg);
            -ms-transform: rotate(43deg);
            -o-transform: rotate(43deg);
            position: absolute;
            bottom: -20px;
            left: 70%;

            .icon-arrow-left {
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
              -moz-transform: scaleX(-1);
              -ms-transform: scaleX(-1);
              -o-transform: scaleX(-1);
            }
          }

          .arrow-left2 {
            left: 75%;
          }

          .arrow-right {
            .icon-arrow-right {
              left: 70%;
              bottom: -85px;
              transform: rotate(43deg);
              -webkit-transform: rotate(43deg);
              -moz-transform: rotate(43deg);
              -ms-transform: rotate(43deg);
              -o-transform: rotate(43deg);
            }
          }

          &:nth-child(even) {
            .left {
              order: 1;
            }

            .right {
              order: 2;
            }
          }
        }
      }
    }

    .for-dogovor .container .wrap .lt {
      padding-left: 0;
    }

    .documents .container ul li {
      width: 100%;

      .btn {
        left: 35px;
        right: auto;
      }
    }

    .documents .container .callback {
      margin-top: 50px;
      margin-bottom: 40px;
    }

    .example .container {
      padding-bottom: 0;

      .wrap .left {
        width: 100%;
        margin-bottom: 35px;
      }
    }
  }

  .callback {
    top: -50px;
    width: 95%;
    height: 95px;
  }

  #tariffs {
    .calculation .container {
      padding-bottom: 0;

      .box {
        padding: 20px;
      }

      .wrap .right {
        z-index: -1;

        .uah-xl {
          display: none;
        }

        .kvt-xl {
          bottom: 160px;
        }
      }

      .price {
        flex-direction: column;

        .lt {
          z-index: -1;
          width: 100%;

          span {
            display: none;
          }
        }

        .rt {
          width: 100%;
        }
      }

      .fact-price {
        width: 100%;

        .uah-md {
          display: none;
        }

        .kvt-m {
          display: none;
        }

        .kvt-s {
          display: none;
        }
      }
    }
  }

  #faq {
    .faq-list .container ul {
      font-size: 1.1rem;

      li {
        margin-bottom: 10px;
        padding: 15px;
      }
    }
  }

  #low .box-offer .container .wrap .right .picture,
  #zvit .box-offer .container .wrap .right .picture {
    margin-top: 0;

    img {
      width: auto;
      height: 100%;
    }
  }

  #low .box-offer .container .wrap .right,
  #zvit .box-offer .container .wrap .right {
    margin-bottom: 0;
  }

  #low .box-offer .container ul li .btn,
  #zvit .box-offer .container ul li .btn {
    right: auto;
    left: 35px;
    bottom: 35px;
  }

  #zvit .box-offer .container .wrap .left {
    margin: 100px 0 50px;
  }

  #news {
    padding-top: 35px;
  }

  .news {
    .news-item .left .picture img {
      width: 100%;
      height: auto;
    }

    .news-item .right {
      padding: 25px;
    }

    .news-item:nth-child(even) {
      .left {
        order: 1;
      }

      .right {
        order: 2;
      }
    }

    .picture {
      height: 320px;
    }
  }

  #contacts {
    .offer .container .title {
      width: 100%;
    }

    .offer .btn {
      display: none;
    }

    .means .container {
      padding-top: 50px;
    }

    .means .container .wrap {
      .item {
        width: 100%;
        height: 320px;
        padding: 35px;
        margin: 15px 0;

        &:nth-last-child(1) {
          margin-top: 15px;
        }
      }
    }

    .timetable {
      .right {
        margin-top: 35px;
      }
    }

    .dispute .container .wrap {
      height: auto;

      .right {
        margin-top: 50px;
      }

      .dispute-text {
        margin-top: 30px;

        p:nth-child(1) {
          margin-top: 10px;
        }
      }
    }

    .control .container .wrap {
      .right {
        margin-top: 35px;
      }
    }
  }

  #cabinet {
    margin-bottom: -15px;

    .container {
      padding-bottom: 0;

      .video-frame {
        width: 100%;
        margin: 25px 0 0;
      }
    }
  }
}
