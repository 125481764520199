@import "./src/css/vars";

.faq-list {
  .container {
    padding-bottom: 0;

    h2 {
      position: relative;
    }

    ul {
      margin-top: 75px;

      li {
        margin-bottom: 35px;
        box-shadow: 0 4px 25px rgba(92, 116, 249, 0.15);
        padding: 35px;
        background-color: $color-5;

        &:hover .question {
          color: $color-2;
        }

        .question {
          position: relative;
          padding-right: 10%;
          width: 100%;
          color: $color-7;
          border: none;
          text-align: left;
          outline: none;
          background: transparent;
          font-size: 1.2rem;
          line-height: 1.3;
          font-weight: 700;
          cursor: pointer;

          &::after {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -5px;
            content: "\002B";
            color: $color-3;
            font-size: 2.7rem;
            font-weight: 400;
            @include transform(0, -50%);
          }
        }

        .question.active {
          color: $color-2;

          &:hover {
            color: $color-7;
          }

          &::after {
            content: "\2212";
          }
        }

        .answer {
          padding-left: 5px;
          padding-right: 10%;

          .line {
            height: 2px;
          }

          p {
            margin: 2% 0;
          }
        }
      }
    }
  }
}
