@import "./src/css/vars";

.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .8);
  position: fixed;
  z-index: 777;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;

  .btn-close {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  &-content {
    width: 520px;
    height: auto;
    padding: 50px;
    background-color: $color-4;
    transform: scale(0.3);
    transition: 0.5s all;

    &.active {
      transform: scale(1);
    }

    form {
      .form-header {
        display: flex;
        justify-content: space-between;

        .picture {
          height: 70px;

          img {
            width: auto;
            height: 100%;
          }
        }

        .radio-btns {
          margin-top: 15px;
        }

      }

      h3 {
        margin: 15px 0;
        color: $color-1;
      }

      label {
        position: relative;
        color: $color-1;
        font-size: 0.8rem;

        input {
          width: 100%;
          margin-top: 5px;
          margin-bottom: 15px;
          border: none;
          background-color: $color-1;
          color: $color-2;
          padding: 8px;
        }

        span {
          position: absolute;
          z-index: 1;
          right: 10px;
          bottom: 0;
        }

        textarea {
          margin-top: 5px;
          margin-bottom: 25px;
          width: 100%;
          height: 100px;
          resize: none;
          padding: 8px;
          border: none !important;
        }
      }

      .btn {
        margin-left: auto;
        width: 230px;

        p {
          color: $color-1;

        }

        &:hover {
          p {
            color: $color-3;

          }
        }

        &[disabled] {
          pointer-events: none;

          p {
            color: $color-7;
          }

          span {
            color: $color-7;
          }

          .circle {
            border-color: $color-7;
          }
        }
      }
    }
  ;
  }
}
