.video-frame {
  width: 600px;
  height: 350px;
  margin-left: auto;
  overflow: hidden;

  video {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }
}
