@import "./src/css/vars";

#news {
  .container {
    h2 {
      line-height: 0.6;
    }

    ul {
      margin-top: 50px;
    }
  }

  .offer {
    margin: 50px 0 35px;
  }
}
