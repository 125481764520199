@import "./src/css/vars";

.for-dogovor {
  .container {
    padding-bottom: 37px;

    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .lt {
        width: 400px;
        height: 770px;
      }

      .rt {
        width: 830px;

        ul {
          margin-top: 50px;

          li {
            display: flex;
            margin: 35px 0;

            .pic {
              position: relative;
              margin-top: 6px;

              i {
                color: $color-3;
                font-size: 2.2rem;
              }

              &::after {
                display: block;
                position: absolute;
                top: 10px;
                left: 7px;
                color: $color-1;
                font-size: 1rem;
                font-weight: 700;
              }
            }

            p {
              margin-left: 20px;
            }

            &:nth-child(1) .pic::after {
              content: "1";
            }

            &:nth-child(2) .pic::after {
              content: "2";
            }

            &:nth-child(3) .pic::after {
              content: "3";
            }

            &:nth-child(4) .pic::after {
              content: "4";
            }

            &:nth-child(5) .pic::after {
              content: "5";
            }

            &:nth-child(6) .pic::after {
              content: "6";
            }

            &:nth-child(7) .pic::after {
              content: "7";
            }

            &:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
