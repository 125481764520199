@import "css/reset";
@import "css/vars";
@import "css/icons";

// @import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@200;400;500;700&display=swap');

html {
  max-width: 100%;
  width: 100%;
  font-size: 18px;
}

body {
  font-family: "Exo 2", sans-serif;
  letter-spacing: 0.025rem;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  padding-top: 135px;
}

.container {
  margin: 0 auto;
  padding: 50px 0;
  width: 1280px;
}

p {
  font-size: 1rem;
}

// Custom styles
.a-center {
  position: absolute;
  top: 50%;
  left: 50%;
  @include translate(-50%, -50%);
}

.show {
  display: block;
}

.hide {
  display: none;
}

.left {
  width: 45%;
}

.right {
  width: 45%;
}

hr {
  opacity: 0.3;
}

// Media Queries
@import "css/media/media1300"; // 1201-1300
@import "css/media/media1200"; // 993-1200
@import "css/media/media992"; // 769-992
@import "css/media/media768"; // 577-768
@import "css/media/media576"; // 340-576
