//====================================================================
/* 768-992px */
//====================================================================
@import "./src/css/vars";

@media screen and (max-width: 992px) {
  .container {
    background-color: rgba(0, 0, 255, $media-color);
    width: 720px;
  }

  body {
    padding-top: 70px;
  }

  #header .container #burger {
    margin-right: -8px;
  }

  #homepage {
    .numbers {
      .container ul li {
        width: 30%;

        .box span {
          font-size: 3.5rem;
        }
      }
    }

    .choose-supplier {
      .container .wrap {
        .lt {
          width: 100%;
        }

        .rt {
          margin-top: 50px;
        }
      }
    }
  }

  .comfortable-office {
    .container .wrap .right .video-frame {
      width: 350px;
    }
  }

  .why-we {
    .container .wrap {
      .left {
        width: 200px;

        .picture {
          height: 940px;
        }
      }

      .right .wrapper .text-box {
        width: 230px;
      }
    }
  }

  #footer {
    .container .wrap .footer-box {
      width: 300px !important;
      margin-bottom: 30px;

      &:nth-child(3) {
        order: 4;
      }

      &:nth-child(4) {
        order: 3;
      }
    }
  }

  #about {
    .director .container .wrap .item {
      width: 230px;

      .picture {
        height: 620px;
      }
    }

    .partners .container {
      .title {
        width: 70%;
      }

      ul li {
        width: 550px;
        margin: 10px 0 10px auto;

        .company .picture {
          height: 45px;
        }
      }
    }
  }

  #client {
    .for-dogovor .container .wrap .rt {
      width: 100%;
    }

    .documents .container ul li {
      margin: 15px 0;
      width: 345px;
    }

    .example .container .wrap .left {
      height: 100%;
      margin: auto 0;
    }
  }

  #faq {
    .faq-list {
      .answer p {
        margin-top: 7%;
      }
    }
  }

  #low .box-offer .container .wrap .right,
  #zvit .box-offer .container .wrap .right {
    z-index: -1;

    .picture img,
    .picture img {
      width: 100%;
      height: auto;
    }
  }

  #low .box-offer .container ul li,
  #zvit .box-offer .container ul li {
    width: 100%;

    &:nth-child(odd) {
      margin-top: auto;
    }

    &:nth-child(even) {
      margin-top: auto;
    }

    &:nth-last-child(2) {
      margin-bottom: 35px;
    }

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .news {
    h2 {
      line-height: 0.3 !important;
    }

    .news-item:nth-child(even) .right {
      padding-left: 25px;
    }
  }

  #contacts {
    .means .container .wrap .item {
      width: 340px;

      &:nth-last-child(1) {
        margin-top: 35px;
      }
    }

    .control .container .wrap .right .picture {
      height: 100%;
    }
  }
}
