@import "./src/css/vars";

.documents {
  .container {
    position: relative;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 15px;

      li {
        margin: 20px 0;
        padding: 35px;
        position: relative;
        width: 400px;
        height: 330px;
        background-color: $color-5;
        box-shadow: 0 4px 25px rgba(92, 116, 249, 0.15);

        .desc {
          margin-top: 25px;
        }

        .btn {
          position: absolute;
          right: 0;
          bottom: 30px;
        }
      }
    }

    .callback {
      position: relative;
      top: auto;
      margin-top: 85px;
      margin-bottom: 50px;
    }
  }
}
