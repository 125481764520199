@import "./src/css/vars";

.btn-close {
  position: absolute;
  z-index: 1;
  right: 22px;
  top: 22px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 2px solid $color-1;
  line-height: 0;
  color: $color-1;
  @include border-radius(50%);

  &:hover {
    border: 2px solid $color-3;
    color: $color-3;
  }
}
