@import "./src/css/vars";

.partner {
  position: relative;
  width: 620px;
  height: 400px;
  margin: 20px 0;
  padding: 30px;
  background-color: $color-5;
  box-shadow: 0 4px 25px rgba(92, 116, 249, 0.1);



  .company {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    h3 {
      width: 65%;
      text-transform: uppercase;
    }

    .picture {
      margin-top: 8px;
      width: auto;
      height: 55px;

      img {
        width: auto;
        height: 100%;
      }
    }
  }

  p {
    margin-top: 35px;
  }

  a {
    position: absolute;
    bottom: 25px;
    left: 25px;
    color: $color-4;

    &:hover {
      color: $color-3;
    }
  }

  .email {
    bottom: 55px;
  }
}
