//====================================================================
/* 1200-1300px */
//====================================================================
@import "./src/css/vars";

@media screen and (max-width: 1300px) {
  .container {
    background-color: rgba(73, 0, 106, $media-color);
    width: 1180px;
  }

  html {
    font-size: 17px;
  }

  .btn {
    width: 285px;
  }

  .offer {
    height: 600px;
  }

  #homepage {
    .choose-supplier {
      .container .wrap {
        .lt {
          width: 770px;

          .desc {
            margin-top: 0;

            p:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }

        .rt h2 {
          width: auto;
        }
      }
    }
  }

  .comfortable-office {
    .container .wrap .left .wrapper .icon-box {
      width: 250px;
    }

    .container .wrap .right .video-frame {
      width: 550px;
    }
  }

  .why-we {
    .container .wrap {
      .left {
        width: 300px;

        .picture {
          width: inherit;
          height: 720px;
        }
      }
    }
  }

  #footer {
    .container .wrap .footer-box {
      width: 24%;

      &:nth-child(2) ul {
        height: 210px;
      }

      &:nth-child(3) ul {
        height: 210px;
      }
    }
  }

  #about {
    .director {
      .container .wrap .item {
        width: 360px;
      }
    }

    .partners .container ul li {
      width: 570px;
    }
  }

  #client {
    .for-dogovor .container .wrap {
      .lt {
        margin: auto 0;
      }

      .rt {
        width: 740px;
      }
    }

    .documents .container ul li {
      margin: 10px 0;
      width: 380px;
    }
  }

  #low .box-offer .container ul li,
  #zvit .box-offer .container ul li {
    width: 555px;
  }

  #low .box-offer .container .wrap .right .picture img,
  #zvit .box-offer .container .wrap .right .picture img {
    width: auto;
    height: 100%;
  }

  #contacts .means .container .wrap .item {
    width: 370px;
  }
}
