@import "./src/css/vars";

.btn {
  width: 295px;
  position: relative;
  background-color: transparent;
  display: flex;
  height: 60px;
  text-align: left;
  color: $color-2;
  border: none;

  .circle {
    position: absolute;
    top: 3px;
    border: 2px solid $color-3;
    @include circle(60px);

    span {
      color: $color-3;
      font-size: 1.7rem;
      position: absolute;
      top: 50%;
      left: 50%;
      @include translate(-50%, -50%);
    }
  }

  p {
    margin: auto;
    margin-left: 75px;
    text-transform: uppercase;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
  }

  &:hover {
    color: $btn-hover !important;

    .circle {
      border: 2px solid $btn-hover;

      .icon {
        color: $btn-hover;
      }
    }
  }
}
