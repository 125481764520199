@import "./src/css/vars";

.calculation {
  .container {
    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .left {
        h1 {
          color: $color-2;
          font-weight: bold;
        }

        .desc {
          margin: 50px 0;
        }

        ul {
          li {
            margin: 10px 0;
          }
        }
      }

      .right {
        position: relative;

        .icon-uah,
        span {
          position: absolute;
        }

        .uah-xl {
          font-size: 15rem;
          color: $color-3;
        }

        .uah-md {
          font-size: 5rem;
          color: $color-3;
          left: 15%;
          bottom: 15%;
        }

        .uah-s {
          font-size: 4rem;
          color: $color-5;
          right: 15%;
          top: 18%;
        }

        span {
          display: block;
          color: $color-5;
        }

        .kvt-xl {
          font-size: 6.5rem;
          right: 0;
          bottom: 0;
        }

        .kvt-md {
          font-size: 3.5rem;
          top: 5%;
          left: 5%;
        }
      }
    }

    .price {
      display: flex;

      .lt {
        position: relative;
        width: 20%;

        .uah-lt {
          position: absolute;
          z-index: 0;
          top: 120px;
          right: -80px;
          color: $color-3;
          font-size: 13rem;
        }
      }

      .rt {
        width: 80%;

        .kvt-m {
          font-size: 3rem;
          color: $color-5;
          display: block;
          text-align: center;
        }

        h3 {
          color: $color-4;
          margin-top: 25px;
          text-align: right;
        }
      }
    }

    .box {
      position: relative;
      z-index: 1;
      margin-top: 50px;
      padding: 35px;
      box-shadow: 0 4px 25px rgba(92, 116, 249, 0.25);
      background-color: rgba(255, 255, 255, 0.9);

      h4 {
        font-weight: 700;

        b {
          font-size: 1.3rem;
          color: $color-3;
        }
      }

      p {
        margin-top: 25px;
        line-height: 1.8;

        i {
          color: $color-3;
          font-style: normal;
          font-weight: 700;
        }
      }
    }

    .fact-price {
      position: relative;
      margin-top: 50px;
      width: 75%;

      h3 {
        color: $color-4;
      }

      .boxes {
        .box {
          position: relative;
          z-index: 1;
          margin-bottom: 50px;

          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }

      .icon-uah,
      span {
        position: absolute;
        z-index: 0;
      }

      .uah-xl {
        bottom: 10%;
        right: -13%;
        font-size: 14rem;
        color: $color-3;
      }

      .uah-md {
        top: 10%;
        right: -23%;
        font-size: 5rem;
        color: $color-5;
      }

      .kvt-m {
        top: 35%;
        right: -30%;
        font-size: 8rem;
        color: $color-5;
      }

      .kvt-s {
        bottom: 0;
        right: -33%;
        font-size: 3rem;
        color: $color-5;
      }
    }
  }
}
