@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?7ecold');
  src:  url('fonts/icomoon.eot?7ecold#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?7ecold') format('truetype'),
    url('fonts/icomoon.woff?7ecold') format('woff'),
    url('fonts/icomoon.svg?7ecold#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-up:before {
  content: "\e918";
}
.icon-arrow-right:before {
  content: "\e916";
}
.icon-arrow-left:before {
  content: "\e917";
}
.icon-trident:before {
  content: "\e915";
}
.icon-close:before {
  content: "\e914";
}
.icon-logo:before {
  content: "\e901";
}
.icon-youtube:before {
  content: "\e902";
}
.icon-fb:before {
  content: "\e903";
}
.icon-insta:before {
  content: "\e904";
}
.icon-phone:before {
  content: "\e905";
}
.icon-user:before {
  content: "\e906";
}
.icon-control:before {
  content: "\e907";
}
.icon-online:before {
  content: "\e908";
}
.icon-zvit:before {
  content: "\e909";
}
.icon-cabinet:before {
  content: "\e90a";
}
.icon-markmap:before {
  content: "\e90b";
}
.icon-envelope:before {
  content: "\e90c";
}
.icon-timetable:before {
  content: "\e90d";
}
.icon-download:before {
  content: "\e90e";
}
.icon-weekend:before {
  content: "\e90f";
}
.icon-time:before {
  content: "\e910";
}
.icon-uah:before {
  content: "\e911";
}
.icon-pdf:before {
  content: "\e912";
}
.icon-dogovor:before {
  content: "\e913";
}
.icon-lightning:before {
  content: "\e900";
}
