@import "./src/css/vars";

.news-item {
  position: relative;
  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: $color-5;
  box-shadow: 0 4px 25px rgba(92, 116, 249, 0.15);

  .left {
    .picture {
      width: 100%;
      max-height: 500px;
      height: 100%;

      img {
        width: auto;
        max-height: 100%;
        height: 100%;
      }
    }
  }

  .right {
    margin: auto;
    padding: 25px 0;

    span {
      color: $color-7;
    }

    h3 {
      margin: 10px 0 25px;
    }

    .btn {
      margin-top: 35px;

      span {
        color: $color-3;
      }
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 50px 80px 50px 50px;

    .wrap {
      position: relative;
      overflow-y: scroll;
      height: 100%;
      padding-right: 20px;

      .line {
        margin-top: 0;
      }

      p {
        margin-top: 50px;
        color: $color-1;
      }

      &::-webkit-scrollbar {
        width: 3px;
        background-color: $color-7;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-3;
      }
    }
  }

  &:nth-child(even) {
    .left {
      order: 2;
      margin: auto 0 auto auto;

    }

    .right {
      order: 1;
      margin-left: 0;
      padding-left: 50px;
    }
  }
}
