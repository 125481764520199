@import "./src/css/vars";

.comfortable-office {
  .container {
    padding-top: 38px;
    padding-bottom: 37px;

    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .left {
        .desc {
          margin: 35px 0;
        }

        .wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        .btn {
          margin-top: 10px;
          width: 350px;
        }
      }

      .right {
        width: 50%;
        margin: auto;
        margin-left: auto;
        margin-right: 0;


      }
    }
  }
}
