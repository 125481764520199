@import "./src/css/vars";

#header {
  position: fixed;
  z-index: 777;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);

  .info {
    background-color: $color-4;
    margin: auto;
    height: 80px;

    .container {
      padding: 0;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      height: inherit;


      .info-links {
        display: flex;

        a {
          color: $color-1;

          &:hover {
            color: $color-3;
          }
        }

        .tel {
          margin: auto;
          margin-right: 40px;
          text-align: right;

          p {
            font-size: 0.8rem;
          }
        }

        .cabinet {
          display: flex;
          margin: auto;
          align-items: center;

          i {
            line-height: 1.4rem;
            font-size: 1.7rem;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .navigation {
    background-color: $color-1;
    height: 55px;

    .container {
      padding: 0;
    }
  }
}

//====================================================================
/* 1200-1300px */
//====================================================================
@media screen and (max-width: 1300px) {
  .container {
    width: 1180px;
  }

  #header {
    .info {
      .logo {
        height: 60px !important;

        i {
          line-height: 1.03;
          margin-left: 0px;
        }
      }
    }
  }
}

//====================================================================
/* 992-1200px */
//====================================================================
@media screen and (max-width: 1200px) {
  .container {
    width: 940px;
  }

  #header {
    .info {
      height: 70px;

      .container .logo {
        height: 51px !important;

        i {
          font-size: 3.2rem;
          line-height: 1;
        }
      }
    }

    .navigation {
      height: 40px;

      .container {
        height: inherit;

        ul {
          padding-top: 0;
          height: inherit;

          li a {
            height: inherit;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

//====================================================================
/* 768-992px */
//====================================================================
@media screen and (max-width: 992px) {
  .container {
    width: 720px;
  }

  #header {
    .info .container .logo {
      height: 45px !important;

      i {
        font-size: 2.8rem;
        line-height: 1.03 !important;
        margin-left: 1px;
      }
    }

    #navigation {
      display: none !important;
    }

    .container {
      position: relative;

      .info-links {
        margin-right: 70px;
      }

      #burger {
        display: block !important;
        position: absolute;
        width: 50px;
        height: 50px;
        top: 10px;
        right: 0;
        cursor: pointer;
        background-color: transparent;
        border: none;

        .burger-i {
          position: absolute;
          z-index: 777;
          top: 9px;
          left: 10px;

          span {
            display: inline-block;
            position: absolute;
            height: 3px;
            width: 30px;
            background-color: $color-1;
            transform: rotate(0deg);
            @include transition(0.2s);
            @include border-radius(5px);

            &:nth-child(1) {
              top: 5px;
            }

            &:nth-child(2) {
              top: 15px;
            }

            &:nth-child(3) {
              top: 25px;
            }
          }
        }
      }

      #burger.active .burger-i span:nth-child(1) {
        top: 15px;
        transform: rotate(45deg);
      }

      #burger.active .burger-i span:nth-child(2) {
        top: 15px;
        width: 0;
        margin-left: 15px;
      }

      #burger.active .burger-i span:nth-child(3) {
        top: 15px;
        transform: rotate(-45deg);
      }
    }

    .navigation {
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100vh;
      background-color: $color-4;
      display: flex;
      align-items: center;

      nav {
        height: 75% !important;

        ul {
          position: absolute;
          top: 50%;
          left: 50%;
          flex-direction: column;
          @include translate(-50%, -46%);

          li {
            text-align: center;
            display: contents;

            a {
              font-size: 1.4rem !important;
              color: $color-1;

              &:hover {
                color: $color-3;
              }

              &.active {
                color: $color-3;
              }

              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

//====================================================================
/* 576-768px */
//====================================================================
@media screen and (max-width: 768px) {
  .container {
    width: 540px;
  }
}

//====================================================================
/* 340-576px */
//====================================================================
@media screen and (max-width: 576px) {
  .container {
    width: 340px;
  }

  #header {
    .info {
      height: 60px;

      .container {
        .logo {
          height: 35px !important;

          i {
            font-size: 2.2rem;
          }
        }

        .info-links {
          margin-right: 40px;

          .tel {
            margin: auto;

            p {
              font-size: 0.5rem;
            }
          }

          .cabinet {
            margin-left: 10px;

            i {
              margin-top: 0;
            }

            span {
              display: none;
            }
          }
        }

        #burger {
          top: 5px;
        }
      }
    }

    .navigation ul {
      padding-top: 0 !important;
    }
  }
}
