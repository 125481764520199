@import "./src/css/vars";

.timetable {
  .container {
    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .left {
        margin: auto 0;

        p {
          margin-top: 35px;
        }
      }

      .right {
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          li {
            width: 220px;
            margin-bottom: 15px;

            .info {
              display: flex;
              margin-top: 15px;

              i {
                margin: auto 0;
                color: $color-4;
                font-size: 1.5rem;
                line-height: 0;
              }

              .box {
                margin-left: 25px;

                span {
                  display: block;
                  margin: 5px 0;
                  font-size: 0.8rem;
                  line-height: 0;
                  color: $color-7;
                }

                b {
                  display: block;
                  line-height: 1.7;
                }
              }
            }

            &:nth-last-child(1) {
              .df {
                margin-top: 20px;

                .box {
                  margin-left: 17px;

                  b {
                    margin-top: -8px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
