//====================================================================
/* 992-1200px */
//====================================================================
@import "./src/css/vars";

@media screen and (max-width: 1200px) {
  .container {
    background-color: rgba(255, 0, 0, $media-color);
    width: 940px;
  }

  html {
    font-size: 16px;
  }

  body {
    padding-top: 110px;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  .btn {
    width: 275px;
  }

  .left {
    width: 47%;
  }

  .right {
    width: 47%;
  }

  .offer {
    height: 570px;

    .container .title {
      width: 520px;
      padding: 40px;
    }
  }

  #homepage {
    .choose-supplier {
      .container .wrap {
        .lt {
          width: 560px;
          height: 650px;
        }

        .rt {
          width: 290px;

          .icon-trident {
            font-size: 8rem;
          }
        }
      }
    }

    .news {
      .container ul li:nth-child(1) .right {
        margin-right: 0;
      }

      .container ul li:nth-child(2) .left {
        margin-right: 0;
      }
    }
  }

  .comfortable-office .container .wrap .left .wrapper .icon-box b {
    margin-left: 15px;
  }

  .comfortable-office .container .wrap .right .video-frame {
    width: 430px;
  }

  .why-we {
    .container .wrap {
      .left {
        width: 270px;
      }

      .right .wrapper {
        .text-box {
          width: 305px;
          padding: 25px;
          margin-bottom: 30px;
        }

        .lt .text-box {
          margin-right: 30px;
        }
      }
    }
  }

  #footer {
    .callback {
      width: 70%;
    }

    .container .wrap {
      .footer-box {
        width: 15%;

        &:nth-child(1) {
          width: 24%;
        }

        &:nth-child(4) {
          width: 29%;
        }
      }
    }
  }

  #about {
    .director .container .wrap .item {
      width: 295px;

      .picture img {
        height: 100%;
      }
    }

    .partners .container ul li {
      width: 460px;
      height: 370px;
      margin: 10px 0;

      .company {
        h3 {
          width: 70%;
        }

        .picture {
          margin-top: 6px;
          height: 30px;
        }
      }
    }
  }

  #client {
    .for-dogovor .container .wrap {
      .lt {
        width: 100%;
        height: 500px;
        padding-left: 22%;
      }

      .rt {
        width: 740px;
        margin-top: 50px;
      }
    }

    .documents .container ul li {
      margin: 20px 0;
      width: 450px;
    }
  }

  #low .box-offer .container ul li,
  #zvit .box-offer .container ul li {
    width: 435px;
  }

  .news {
    h2 {
      line-height: 0.3 !important;
    }
  }

  .news-item .right {
    padding: 25px;
  }

  #contacts .means .container .wrap .item {
    width: 300px;
    height: auto;
    padding: 20px;
  }
}
