@import "./src/css/vars";

.callback {
  position: absolute;
  top: -55px;
  left: 50%;
  width: 670px;
  height: 110px;
  display: block;
  border: none;
  text-transform: uppercase;
  font-size: 1.3rem;
  color: $color-1;
  letter-spacing: 0.05rem;
  background-color: $color-3;
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  @include transform(-50%, 0);
  @include border-radius(100px);
  @include transition(0.3s);

  &:hover {
    box-shadow: 0 10px 15px rgba(0, 217, 0, 0.8);
  }
}
