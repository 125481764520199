@import "./src/css/vars";

.numbers {
  .container {
    padding-top: 85px;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 50px;
      justify-content: space-around;

      li {
        width: 25%;
        text-align: center;

        .box {
          display: inline-block;
          position: relative;
          width: auto;
          margin-bottom: 10px;

          span {
            position: relative;
            z-index: 2;
            font-size: 4.7rem;
            color: $color-3;
            font-weight: 100;
          }

          .icon {
            z-index: 1;
            font-size: 5rem;
            color: $color-5;
          }
        }
      }
    }
  }
}
