@import "./src/css/vars";

.dispute {
  padding-bottom: 50px;

  .container {
    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 600px;

      .left {
        height: inherit;

        .picture {
          width: 100%;
          height: inherit;
        }
      }

      .right {
        position: relative;
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .dispute-text {
          height: 410px;
          padding-right: 3%;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 3px;
            background-color: $color-5;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-3;
          }

          ul {
            padding-left: 25px;

            li {
              list-style: disc;
            }
          }

          p {
            margin: 20px 0;
          }
        }

        &::after {
          content: "";
          display: block;
          width: 99%;
          height: 50px;
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 70%, #ffffff 100%);
        }
      }
    }
  }
}
