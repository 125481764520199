@import "./src/css/vars";

.example {
  .container {
    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .left {
        width: 48%;
        box-shadow: 0 4px 50px rgba(92, 116, 249, 0.2);

        .picture {
          width: 100%;
          height: auto;
        }
      }

      .right {
        margin: auto 0;

        .desc {
          margin: 35px 0;
        }

        .btn {
          width: 330px;
        }
      }
    }
  }
}
