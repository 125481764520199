@import "./src/css/vars";

.means {
  .container {
    padding-top: 100px;

    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        width: 400px;
        height: 380px;
        padding: 35px;
        background-color: $color-5;
        box-shadow: 0 4px 25px rgba(92, 116, 249, 0.15);

        .title {
          display: flex;
          justify-content: space-between;

          i {
            display: block;
            margin-top: 7px;
            font-size: 1.5rem;
            color: $color-3;
          }
        }

        p {
          font-size: 0.8rem;
          line-height: 2;
          color: $color-7;
        }

        a {
          font-size: 1.2em;
          color: $color-4;

          &:hover {
            color: $color-3;
          }
        }

        .others {
          margin-top: 20px;

          a {
            display: block;
          }
        }

        .address {
          margin-top: 20px;
        }
      }
    }
  }
}
