@import "./src/css/vars";

.icon-box {
  display: flex;
  width: 280px;
  justify-content: space-between;
  margin-bottom: 35px;

  i {
    font-size: 2.8rem;
    color: $color-3;
  }

  strong {
    width: 78%;
  }
}
