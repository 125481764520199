@import "./src/css/vars";

.why-we {
  .container {
    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .left {
        width: 400px;

        .picture {
          width: 400px;
          height: 850px;
        }
      }

      .right {
        width: auto;

        .wrapper {
          display: flex;
          flex-wrap: wrap;
          margin-top: 35px;
          justify-content: space-between;

          .lt {
            .text-box {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
