@import "./src/css/vars";

#low,
#zvit {
  .box-offer {
    .container {
      position: relative;
      padding-bottom: 0;

      .wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .left {
          margin: 100px 0;

          h1 {
            color: $color-2;
            font-weight: 700;
          }

          .line {
            margin-top: 35px;
          }

          p {
            margin-top: 35px;
          }
        }

        .right {
          position: relative;
          margin-bottom: -200px;

          i {
            text-transform: uppercase;
            background: linear-gradient(0deg, #ffcc00 30%, #005eff 70%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: flex;
            font-size: 35rem;
          }

          .picture {
            margin-top: 70px;
            width: 100%;
            height: 200px;

            img {
              width: auto;
              height: 200px;
            }
          }
        }
      }

      ul {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          position: relative;
          padding: 35px;
          width: 607px;
          height: 380px;
          background-color: $color-5;
          box-shadow: 0 4px 25px rgba(92, 116, 249, 0.15);
          margin-bottom: 35px;

          &:nth-child(1) {
            margin-top: 0;
          }

          &:nth-child(odd) {
            margin-top: -50px;
          }

          &:nth-child(even) {
            margin-top: 10px;
          }

          &:nth-last-child(1),
          &:nth-last-child(2) {
            margin-bottom: 0;
          }

          .desc {
            font-size: 0.8rem;
            color: $color-7;
          }

          h4 {
            margin-top: 3%;
            font-size: 1.1rem;
          }

          .btn {
            width: 220px;
            position: absolute;
            right: 30px;
            bottom: 35px;
          }
        }
      }
    }
  }
}
