@import "./src/css/vars";

#footer {
  position: relative;
  margin-top: 100px;
  background-color: $color-4;
  padding-top: 30px;

  .container {
    padding-bottom: 35px;

    ul {
      li {
        a i {
          color: $color-2;
        }

        a:hover {
          color: $color-3 !important;

          i {
            color: $color-3;
          }
        }
      }
    }

    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .footer-box {
        width: 23%;

        &:nth-child(1) {
          p {
            margin-top: 50px;
          }

          ul {
            display: flex;
            width: 165px;
            margin-top: 15px;
            justify-content: space-between;

            li {
              i {
                font-size: 2rem;
              }
            }
          }
        }

        &:nth-child(2) {
          .container {
            width: auto;
            padding: 0;
          }

          ul {
            flex-direction: column;
            justify-content: space-between;
            height: 220px;

            li {
              a {
                color: $color-2;
                line-height: 2.2rem;
              }

              a.active {
                color: $color-3;
              }

              &:nth-child(1),
              &:nth-child(6),
              &:nth-child(7),
              &:nth-child(8),
              &:nth-child(9) {
                display: none;
              }
            ;
            }
          }
        }

        &:nth-child(3) {
          .container {
            width: auto;
            padding: 0;
          }

          ul {
            flex-direction: column;
            justify-content: space-between;
            height: 220px;

            li {
              a {
                color: $color-2;
                line-height: 2.2rem;
              }

              a.active {
                color: $color-3;
              }

              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5) {
                display: none;
              }
            ;
            }
          }
        }

        &:nth-child(4) {
          ul {
            li {
              display: flex;
              margin: 5px 0;

              &:nth-child(1) {
                margin-bottom: 15px;
              }

              i {
                display: block;
                margin-top: 3px;
                color: $color-3;
              }

              a {
                margin-left: 10px;
                color: $color-2;

                p {
                  font-size: 0.7rem;
                }
              }
            }

            .email {
              margin: 20px 0;
            }
          }
        }
      }
    }

    hr {
      margin: 35px 0 30px;
      border: 1px solid $color-3;
    }

    .development {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      color: $color-2;

      .copy {
        display: flex;
      }

      b {
        margin: 0 10px;
        font-weight: normal;
      }

      a {
        color: $color-2;

        &:hover {
          color: $color-3;
        }
      }
    }
  }
}
