@import "./src/css/vars";

.partners {
  .container {
    padding-bottom: 30px;

    .title {
      width: 50%;

      h2 {
        line-height: 0.8;
      }

      p {
        margin-top: 30px;
      }
    }

    ul {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
