// Setting
$color-1: #fff;
$color-2: #000;
$color-3: #00d900;
$color-4: #5c74f9;
$color-5: #eeeeee;
$color-6: #ff5555;
$color-7: #656565;

$btn: #00d900;
$btn-text: #fff;
$btn-hover: #00d900;

$modal-bg: rgba(0, 0, 0, 0.8);
$modal-form: #5c74f9;

$media-color: 0;

@mixin border-radius($br) {
  border-radius: $br;
  -webkit-border-radius: $br;
  -moz-border-radius: $br;
  -ms-border-radius: $br;
  -o-border-radius: $br;
}

@mixin transition($tr) {
  transition: all $tr ease-in-out;
  -webkit-transition: all $tr ease-in-out;
  -moz-transition: all $tr ease-in-out;
  -ms-transition: all $tr ease-in-out;
  -o-transition: all $tr ease-in-out;
}

@mixin transform($h, $v) {
  transform: translate($h, $v);
  -webkit-transform: translate($h, $v);
  -moz-transform: translate($h, $v);
  -ms-transform: translate($h, $v);
  -o-transform: translate($h, $v);
}

@mixin translate($h, $v) {
  transform: translate($h, $v);
  -webkit-transform: translate($h, $v);
  -moz-transform: translate($h, $v);
  -ms-transform: translate($h, $v);
  -o-transform: translate($h, $v);
}

@mixin rotate($val) {
  transform: rotate($val);
  -webkit-transform: rotate($val);
  -moz-transform: rotate($val);
  -ms-transform: rotate($val);
  -o-transform: rotate($val);
}

@mixin scale($sc) {
  transform: scale($sc);
  -webkit-transform: scale($sc);
  -moz-transform: scale($sc);
  -ms-transform: scale($sc);
  -o-transform: scale($sc);
}

@mixin circle($val) {
  width: $val;
  height: $val;
  @include border-radius(50%);
}

@mixin img {
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 100%;
  width: auto;
  height: 100%;
}
