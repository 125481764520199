//====================================================================
/* 340-576px */
//====================================================================
@import "./src/css/vars";

@media screen and (max-width: 576px) {
  .container {
    background-color: rgba(0, 217, 255, $media-color);
    width: 340px;
    padding: 20px 0;
  }

  body {
    padding-top: 0;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    line-height: 1.2;
  }

  p {
    font-size: 0.9rem;
  }

  .line {
    width: 100px;
    height: 5px;
  }

  .btn .circle img {
    margin: auto !important;
    width: auto !important;
    height: 25px !important;
  }

  .btn {
    width: 215px;
    height: 52px;

    .circle {
      width: 48px;
      height: 48px;
    }

    p {
      font-size: 1rem;
      margin-left: 65px;
    }
  }

  .offer {
    height: 100vh;

    .offer-bg {
      img {
        width: auto;
        height: 100%;
      }
    }

    .container .title {
      width: 96% !important;
      padding: 20px;

      .btn {
        width: 295px;
        margin-top: 30px;
      }
    }
  }

  #header .info .container .info-links .cabinet i {
    margin-right: 0;
  }

  #homepage {
    .numbers {
      .container {
        padding-top: 45px;
        padding-bottom: 15px;

        ul {
          margin-top: 20px;

          li {
            width: 100%;
            text-align: left;
            margin: 20px 0;

            &:nth-last-child(1) {
              margin-bottom: 0;
            }

            p {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .choose-supplier {
      .container {
        padding-top: 0;

        .wrap {
          .lt {
            margin-top: 35px;
            height: 680px;

            .desc {
              padding: 15px;

              .line {
                margin-left: -15px;
              }

              p {
                font-size: 1rem;
              }
            }
          }

          .rt {
            margin-top: 35px;

            .trident {
              i {
                font-size: 4rem;
              }
            }
          }
        }
      }
    }

    .news {
      .container ul li {
        margin-top: 35px;

        .left .picture {
          height: auto;
        }
      }
    }
  }

  .comfortable-office {
    .container {
      padding: 0;

      .wrap {
        .left .wrapper .icon-box b {
          width: 100%;
          margin: auto;
          margin-left: 20px;
        }

        .left .btn {
          width: 300px;
        }

        .right {
          .video-frame {
            width: 340px;
            height: 220px;
          }
        }
      }
    }
  }

  .why-we {
    .container .wrap {

      .left .picture {
        height: 230px;
      }

      .right .wrapper {
        .lt .text-box:nth-child(3) {
          margin-bottom: 0;
        }

        .rt {
          margin-top: 30px;
        }

        .text-box {
          width: 100%;
        }
      }
    }
  }

  #footer {
    margin-top: 80px;
    padding-top: 15px;

    .callback {
      height: 90px;
      top: -45px;
    }

    .container {
      padding-top: 50px;
      padding-bottom: 20px;

      .wrap {
        .footer-box:nth-child(1) {
          margin-top: 0;

          .logo-color {
            margin-top: 15px;
            height: 55px;

            img {
              height: 55px;
            }
          }

          ul {
            width: 132px;
            margin-top: 7px;

            li i {
              font-size: 1.7rem;
            }
          }
        }

        .footer-box:nth-child(2) {
          margin-top: 5px;

          ul {
            height: auto;

            padding-right: 10px;

            li {
              margin: 8px 0;
              text-align: left;

              a {
                font-size: 1rem;
              }
            }
          }
        }

        .footer-box:nth-child(3) {
          margin-top: 5px;

          ul {
            height: auto;

            li {
              margin: 8px 0;

              a {
                font-size: 1rem;
              }
            }
          }
        }

        .footer-box:nth-child(4) {
          margin-top: 220px;

          ul li a p {
            font-size: 0.8rem;
          }
        }
      }

      hr {
        margin: 20px 0;
      }
    }
  }

  #about {
    .why-we .container {
      padding-top: 50px;
    }

    .director .container .wrap .item .picture {
      height: 100%;
    }

    .partners {
      margin-top: 15px;

      .container {
        .title {
          width: 100%;
        }

        ul li {
          height: auto;
          padding-bottom: 100px;

          .company {
            flex-direction: column;

            h3 {
              width: 100%;
              order: 2;
            }

            .picture {
              margin: 0 0 20px;
              order: 1;
            }
          }
        }
      }
    }
  }

  #client {
    .conditions .container ul {
      li:nth-child(1) {
        margin-top: 20px;
      }

      li:nth-last-child(1) {
        margin-bottom: 0;
      }

      li {
        margin-top: 0;
        margin-bottom: 35px;

        .left .number {
          i {
            font-size: 8rem;
          }

          &::after {
            font-size: 5rem;
          }
        }

        .right {
          margin-top: 20px;

          .wrapper .desc {
            margin: 15px 0 25px;
          }
        }

        .arrow-left {
          display: none;
        }

        .arrow-left2 {
          display: none;
        }

        .icon-arrow-left {
          display: none;
        }

        .icon-arrow-right {
          display: none;
        }
      }
    }

    .for-dogovor .container {
      padding-bottom: 15px;

      .wrap .rt {
        width: 100%;
        margin-top: 35px;

        ul {
          margin-top: 0;

          li {
            margin: 25px 0;
          }
        }
      }
    }

    .comfortable-office .container .wrap .left .wrapper .item {
      width: 100%;
    }

    .example {
      padding-bottom: 25px;
    }

    .callback {
      margin-top: 15px;
    }
  }

  .callback {
    width: 320px !important;
  }

  #tariffs {
    padding-bottom: 35px;

    .calculation {
      padding-top: 75px;

      .container {
        .price .lt .uah-lt {
          right: 0;
        }

        .wrap .left .desc {
          margin: 25px 0;
        }

        .fact-price {
          .boxes .box {
            margin: 25px 0 0;
          }

          .uah-xl {
            right: 0;
          }
        }
      }
    }
  }

  #faq {
    padding-bottom: 10px;

    .line {
      margin-top: 30px;
    }

    .faq-list .container ul {
      margin-top: 30px;

      .question {
        font-size: 1rem;
        line-height: 1.2;
        color: $color-2;

        &.active {
          margin-bottom: 15px;
        }
      }

      .answer {
        padding-bottom: 0;

        p {
          font-size: 0.8rem;
          line-height: 1.4;
        }
      }
    }
  }

  #low {
    padding-bottom: 25px;

    .box-offer .container .wrap .right i {
      font-size: 25rem;
    }
  }

  #low .box-offer .container ul li,
  #zvit .box-offer .container ul li {
    padding: 25px;
    margin-left: 0;
    margin-right: 0;

    h4 {
      font-size: 1rem;
    }

    .btn {
      left: 25px;
      bottom: 25px;
      right: auto;
    }
  }

  #low .box-offer .container .wrap .left,
  #zvit .box-offer .container .wrap .left {
    margin: 70px 0 35px;

    .line {
      margin-top: 20px;
    }
  }

  #low .box-offer .container .wrap .left p,
  #zvit .box-offer .container .wrap .left p {
    margin-top: 15px;
  }

  #low .box-offer .container .wrap .right .picture,
  #zvit .box-offer .container .wrap .right .picture {
    margin-top: 0;
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  #low .box-offer .container .wrap .right,
  #zvit .box-offer .container .wrap .right {
    margin-bottom: -100px;
  }

  #low .box-offer .container ul li {
    height: 330px;
  }

  #zvit {
    padding-bottom: 20px;

    .box-offer .container ul {
      margin-top: 140px;

      li {
        height: 290px;
      }

      .box-offer .container .wrap .right .picture {
        width: 80%;
      }
    }
  }

  .news-item .overlay {
    padding: 70px 40px 25px 20px;
  }

  .news {
    h2 {
      line-height: 0 !important;
    }

    .line {
      margin-top: 40px;
    }
  }

  #news {
    padding-top: 90px;
    padding-bottom: 0;

    h4 {
      font-size: 1.1rem;
    }

    .picture {
      height: 230px;
    }
  }

  #contacts {
    margin-bottom: 15px;

    .means .container {
      padding-top: 30px;

      .wrap .item {
        padding: 25px;
      }
    }

    .timetable {
      .container {
        padding: 15px 0;

        .wrap .left p {
          margin-top: 25px;
        }

        .wrap .right {
          margin-top: 20px;
        }

        .wrap .right ul li {
          width: 100%;
          margin-bottom: 0;

          &:nth-child(1) {
            h4 {
              margin-top: 0;
            }
          }

          h4 {
            font-size: 1.4rem;
            margin-top: 15px;
          }

          .df {
            margin-top: 15px;

            i {
              font-size: 2.2rem;
              color: $color-3;
              margin-top: 19px;
            }

            b {
              font-size: 1.3rem;
              color: $color-7;
            }
          }

          &:nth-last-child(1) {
            .df i {
              margin-top: 14px;
            }
          }
        }
      }
    }

    .dispute .container .wrap {
      .dispute-text {
        margin-top: 25px;
      }

      .right {
        margin-top: 25px;
      }
    }

    .control {
      .container {
        padding-top: 10px;

        .wrap .left p {
          margin-top: 25px;
        }

        .wrap .left ul li {
          margin: 25px 0;

          .digital {
            margin: auto;
          }
        }
      }
    }
  }

  #cabinet {
    margin-top: 55px;
  }

  .modal .btn-close {
    top: 10px;
    right: 20px;
  }

  .modal-content {
    width: 90%;
    padding: 20px;

    form .btn {
      width: 175px;
    }

    form .form-header .picture {
      height: 50px;
    }

    form .form-header .radio-btns {
      margin-top: 4px;
    }

    form label textarea {
      margin-bottom: 10px;
      height: 80px;
    }
  }

  .up-arrow {
    color: $color-3;
    font-size: 2.5rem;
  }

  .modal {
    form {
      .btn {
        margin-right: 6px;
      }
    }
  }
}
