@import "./src/css/vars";

.offer {
  position: relative;
  height: 685px;

  &-bg {
    position: absolute;
    z-index: 0;
    overflow: hidden;
    width: 75%;
    height: 100%;
    top: 0;
    right: 0;
    margin-left: auto;

    img {
      min-width: 100%;
      min-height: 100%;
    }
  }

  .container {
    margin: 0 auto;
    padding: 0;
    height: inherit;
    position: relative;
    z-index: 1;

    .title {
      position: absolute;
      top: 50%;
      left: 0;
      @include translate(0, -50%);
      width: 620px;
      background-color: $color-4;
      padding: 60px;

      .desc {
        margin-top: 25px;
        color: $color-1;
      }

      .btn {
        width: 310px;
        margin-top: 50px;
        color: $color-1;
      }
    }
  }
}
