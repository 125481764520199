@import "./src/css/vars";

.control {
  padding-top: 50px;

  .container {
    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .left {
        p {
          margin-top: 35px;
        }

        ul {
          li {
            position: relative;
            margin: 35px 0;

            &:nth-child(1) a .digital::after {
              content: "01";
            }

            &:nth-child(2) a .digital::after {
              content: "02";
            }

            &:nth-child(3) a .digital::after {
              content: "03";
            }

            &:nth-last-child(1) a {
              margin-bottom: 0;
            }

            a {
              position: relative;
              display: flex;

              .digital {
                position: relative;

                i {
                  color: $color-5;
                  font-size: 3.2rem;
                  @include transform(0, -50%);
                }

                &::after {
                  position: absolute;
                  z-index: 1;
                  left: 0;
                  top: 50%;
                  display: block;
                  color: $color-3;
                  font-size: 2rem;
                  @include transform(0, -50%);
                }
              }
            }

            h4 {
              margin: auto auto auto 70px;
              color: $color-2;
            }

            &:hover h4 {
              color: $color-3;
            }
          }
        }
      }

      .right {
        .picture {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
