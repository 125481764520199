@import "./src/css/vars";

.conditions {
  .container {
    ul {
      li {
        position: relative;
        display: flex;
        margin: 70px 0;
        flex-wrap: wrap;
        justify-content: space-between;

        &:nth-child(even) {
          .left {
            order: 2;
          }

          .right {
            order: 1;
          }
        }

        .left {
          margin: auto;
          text-align: center;

          .number {
            position: relative;

            i {
              color: $color-5;
              font-size: 15rem;
            }

            &::after {
              display: block;
              font-size: 12rem;
              color: $color-3;
              position: absolute;
              font-weight: 100;
              top: 50%;
              left: 50%;
              @include transform(-50%, -50%);
            }
          }
        }

        .icon-arrow-left,
        .icon-arrow-right {
          font-size: 7rem;
          color: $color-7;
          top: auto;
          bottom: -50px;
          @include transform(-50%, 0);
        }

        .right {
          .wrapper {
            .desc {
              margin: 35px 0 50px;
            }
          }
        }

        &:nth-child(1) {
          margin-top: 0;
        }

        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        &:nth-child(1) .number::after {
          content: "01";
        }

        &:nth-child(2) .number::after {
          content: "02";
        }

        &:nth-child(3) .number::after {
          content: "03";
        }

        &:nth-child(4) .number::after {
          content: "04";
        }
      }
    }
  }
}
