@import "./src/css/vars";

nav {
  width: 100%;
  height: 55px;

  ul {
    width: 100%;
    display: flex;
    justify-content: space-around;

    li {
      display: flex;
      align-items: center;
      text-align: center;

      a {
        font-size: 1rem;
        color: $color-2;
        font-weight: 500;
        line-height: 2.9;
        border-bottom: 3px solid transparent;

        &::after {
          content: '';
          display: block;
          cursor: pointer;
          width: 0;
          height: 3px;
          background-color: $color-3;
          @include transition(.3s);
        }

        &:hover::after {
          width: 100%;
        }

        &.active {
          color: $color-3;
          pointer-events: none;
        }
      }
    }
  }
}
