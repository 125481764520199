.picture {
  overflow: hidden;
  width: inherit;
  height: inherit;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
