.director {
  .container {
    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        width: 400px;

        &:nth-child(1) {
          padding-right: 100px;

          h2 {
            position: relative;
          }
        }

        p {
          margin: 25px 0;
        }

        .picture {
          width: 100%;
          height: 540px;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
