@import "./src/css/vars";

.logo {
  position: relative;
  margin: auto 0;
  height: 63px;

  i {
    color: $color-1;
    font-size: 3.5rem;
    opacity: 1;
  }

  &:hover {
    i {
      opacity: 0;
    }

    .logo-color {
      display: block !important;

      img {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: auto;
        height: 100%;
      }
    }
  }
}
